import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Styles SCSS
import '../sass/home.scss';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

import 'slick-carousel';

import 'jquery.marquee';

// pushbar
import Pushbar from './utils/pushbar';

$(document).ready(() => {
	jQuery('.translate option:contains("Select Language")').text('langues');
	jQuery('.translate option:contains("English")').text('EN');
	jQuery('.translate option:contains("French")').text('FR');
	jQuery('.translate option:contains("Spanish")').text('ES');
	jQuery('.translate option:contains("FR")').attr('selected', true);

	$('.ticker').marquee({
		duration: 13000,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: false,
		pauseOnHover: true
	});

	$('.actualites__slider').slick({
		dots: true,
		infinite: true,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 2000,
		appendDots: '.actualites__dots',
		nextArrow: '<svg class="svg-inline--fa fa-chevron-right fa-w-10 slick-arrow__right slick-arrow" style="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>',
		prevArrow: '<svg class="svg-inline--fa fa-chevron-left fa-w-10 slick-arrow__left slick-arrow" style="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>'
	});

	$('.flash__slider').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: false,
		arrows: false,
	});

	new Pushbar({
		blur: true,
		overlay: true
	});
});
